import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom';

import store, { history } from './store/configureStore'; //?
import jwt from './utils/jwt';
import { getAuthToken, getRefreshToken, getVerificationToken } from './utils/lsMethods';
import './i18n';

import FullPageLoaderNoText from './components/loaders/FullPageLoader/FullPageLoaderNoText';
import loadlazy from './utils/componentLoader';
import HistoryStorage from './utils/HistoryStorage';
import ToastrView from './components/toastr/ToastrView';


//#region init block

//Styles
import('bootstrap/dist/css/bootstrap.css').then(m => { });
import("./styles/main.scss").then(m => { });
import('./App.css').then(m => { });

//import('./i18n').then(m => { App.ModulesLoadedCount++; });


import("./utils/amplitude").then((amp) => {
    amp.initAmplitude();
    const authToken = getAuthToken();
    if (authToken != null) {
        const tokenData = jwt.decode(authToken);
        amp.setAmplitudeUserId(tokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
        amp.sendAmplitudeData(amp.amplitudeTypes.OPEN_WEBSITE, { 'registered': true });
    } else {
        amp.sendAmplitudeData(amp.amplitudeTypes.OPEN_WEBSITE, { 'registered': false });
    }
});

import("axios").then((axios) => {
    if (window.location.hostname != "localhost") {
        axios.defaults.timeout = 120000;        
    }
    if (navigator.userAgent == "ReactSnap") {
        axios.defaults.baseURL = 'https://localhost:44374/';
    }
});

import("retry-axios").then((rax) => {
    const interceptorId = rax.attach();
});

import('./login/actions').then((loginActions) => {
    import('./utils/setAuthorizationToken').then((setAuthorizationTokenModule) => {
        if (getAuthToken()) {
            let data = { authToken: getAuthToken(), refreshToken: getRefreshToken() }
            loginActions.loginByJWT(data, store.dispatch)
        }
        else if (getVerificationToken()) {
            let token = getVerificationToken();
            setAuthorizationTokenModule.default(token);
        }
    })
})

//#endregion


//#region lazy Pages and components
//const ReduxToastr = loadlazy(() => import('react-redux-toastr'));
const AppTitleHelper = loadlazy(() => import('./AppTitleHelper'));
const LiveSupport = loadlazy(() => import('./feedback/liveSupport/LiveSupport'));
const QuestionnaireButton = loadlazy(() => import('./questionnairePT/scenes/QuestionnaireButton'));
const ErrorBoundary = loadlazy(() => import('./errorBoundary'));

const MainLayout = loadlazy(() => import('./components/layouts/MainLayout'));
const MainLayoutFullWidth = loadlazy(() => import('./components/layouts/MainLayoutFullWidth'));
const GuestRoute = loadlazy(() => import('./components/routes/GuestRoute'));
const UserRoute = loadlazy(() => import('./components/routes/UserRoute'));
const MyProfileLayout = loadlazy(() => import('./components/layouts/MyProfileLayout'));
const MainLayoutHome = loadlazy(() => import('./components/layouts/MainLayoutHome'));
const MyCompanyLayout = loadlazy(() => import('./siteCompany/components/menu/CompanyLayout'));

const UserRouteExecutor = loadlazy(() => import('./components/routes/UserRouteExecutor'));
const UserRoutePortfolio = loadlazy(() => import('./components/routes/UserRoutePortfolio'));
const UnConfirmAccountRoute = loadlazy(() => import('./components/routes/UnConfirmAccountRoute'));
const UserRouteSeller = loadlazy(() => import('./components/routes/UserRouteSeller'));

const CookiesBanner = loadlazy(() => import('./cookies/CookiesBanner'));


const HomeCatalogTurnkeyPage = loadlazy(() => import('./home/catalogTurnkeyv2/CatalogPage'));
const MyProjectsV3 = loadlazy(() => import('./projects/myprojectsv3/MyProjectsV3'));
const MyProductDetails = loadlazy(() => import('./projects/myprojectsv3/MyProductDetails'));


const HomePage = loadlazy(() => import('./home/homePage/HomePage'));
const HomeCatalogPageV4 = loadlazy(() => import('./home/catalogv4/CatalogPageV4'));

const CatalogProductDetails = loadlazy(() => import('./home/catalogProductDetailsV2/ProductDetails'));
const ExecutorDetailsPage = loadlazy(() => import('./home/catalogProductDetailsV2/ExecutorDetailsPage'));

const CatalogProductPageDIY = loadlazy(() => import('./home/catalogProductDetailsDIY/ProductDetails'));
const GoodsListPage = loadlazy(() => import('./goods/goodslist/GoodsListPage'));
const ManufacturersPage = loadlazy(() => import('./manufacturer/ManufacturersPage'));
const GoodsDetailsPage = loadlazy(() => import('./goods/goodsdetails/GoodsDetailsPage'));
const ElementsListPage = loadlazy(() => import('./elements/elementslist/ElementsListPage'));
const ElementDetailsPage = loadlazy(() => import('./elements/elementDetails/ElementDetailsPage'));

const SolutionFooter = loadlazy(() => import('./solutions/static/SolutionFooter'));

const RegisterPage = loadlazy(() => import('./register/registerSimple/RegisterPage'));
const LoginPage = loadlazy(() => import('./login/scenes'));
const RecoverPasswordPage = loadlazy(() => import('./login/scenes/RecoverPasswordPage'));
const MyInfoPage = loadlazy(() => import('./myprofile/myinfo/scenes'));
const MySpecialtyPage = loadlazy(() => import('./myprofile/myspecialty/scenes/index'));
const EditSiteProtforlioPage = loadlazy(() => import('./myprofile/sitePortfolio/EditSiteProtforlioPage'));
const VerificateAccountPage = loadlazy(() => import('./verify/scenes/index'));
const MyElementsPage = loadlazy(() => import('./myprofile/myelements/scenes/index'));
const ElementMatchPage = loadlazy(() => import('./myprofile/elementmatch/scenes/ElementMatchPage'));
const MyElementPage = loadlazy(() => import('./myprofile/myelements/scenes/MyElementPage/index'));
const BulkUploadInfoPage = loadlazy(() => import('./myprofile/bulkuploadinfo/BulkUploadInfoPage'));
const MyEditorSessions = loadlazy(() => import('./myprofile/editorSessions/MyEditorSessions'));
const MyGoods = loadlazy(() => import('./myprofile/goods/myGoods/MyGoods'));
const AddGoods = loadlazy(() => import('./myprofile/goods/addGoods/AddGoods'));
const AddPaints = loadlazy(() => import('./myprofile/goods/addGoods/AddPaint'));
const AddProfsheet = loadlazy(() => import('./myprofile/goods/addGoods/AddProfsheet'));
const AddMetal = loadlazy(() => import('./myprofile/goods/addGoods/AddMetal'));
const AddGateAutomation = loadlazy(() => import('./myprofile/goods/addGoods/AddGateAutomation'));
const MyFeedbacksPage = loadlazy(() => import('./feedback/MyFeedbacksPage'));
const FeedbackChatPage = loadlazy(() => import('./feedback/FeedbackChatPage'));

const MyProjectsPageV2 = loadlazy(() => import('./projects/myprojects/MyProjectsPageV2'));
const CalculateFactorsPage = loadlazy(() => import('./myprofile/calculationfactors/scenes/CalculationFactorsPage'));
const EditUserProductsPage = loadlazy(() => import('./myprofile/myproductportfolio/EditUserProductsPage'));

const AllInstructionsPage = loadlazy(() => import('./instruction/global/AllInstructionsPage'));
const InstructionPage = loadlazy(() => import('./instruction/details/InstructionPage'));

const FavoriteProductsPage = loadlazy(() => import('./favorites/favoriteProducts/FavoriteProductsPage'));
const BasketPage = loadlazy(() => import('./basket/basketPage/BasketPage'));


const MySketchesPage = loadlazy(() => import('./autoBuildEdit/sketches/MySketches'));
const MySketchesElementsPage = loadlazy(() => import('./autoBuildEdit/sketches/MySketchesElements'));
const EditSketchPage = loadlazy(() => import('./autoBuildEdit/editSketch/EditSketchPage'));
const MyAutoBuildTemplatesPage = loadlazy(() => import('./autoBuildEdit/templates/MyTemplates'));
const EditAutoBuildTemplatePage = loadlazy(() => import('./autoBuildEdit/editTemplate/EditTemplatePage'));

const AutoBuildCreateProduct = loadlazy(() => import('./autoBuild/createProduct/CreateProductPage'));

const EmptyPage = loadlazy(() => import('./EmptyPage'));

const MainSolutionPage = loadlazy(() => import('./solutions/Solution'));
const SolutionPage = loadlazy(() => import('./solutions/SolutionById'));

const BalancePage = loadlazy(() => import('./myprofile/balance/Balance'));

const ExecutorSettingsPage = loadlazy(() => import('./embedding/executorSettings/ExecutorSettingsPage'));
const EmbedingCatalogPage = loadlazy(() => import('./embedding/userPage/EmbedingCatalogPage'));
const EmbeddingDetailsPage = loadlazy(() => import('./embedding/userPage/EmbeddingDetailsPage'));

const SiteCompanyServicePage = loadlazy(() => import('./siteCompany/siteCompanyService/SiteCompanyServicePage'));

const CompanyPage = loadlazy(() => import('./siteCompany/company/CompanyPage'));
const CompanyPage3dCatalog = loadlazy(() => import('./siteCompany/company/3dCatalog'));
const CompanyPageContacts = loadlazy(() => import('./siteCompany/company/Contacts'));
const CompanyPagePortfolio = loadlazy(() => import('./siteCompany/company/Portfolio'));
const CompanyPageProductsDetails = loadlazy(() => import('./siteCompany/components/catalog/CompanyProductDetailsPage'));

const CalculatorPage = loadlazy(() => import('./calculator/components/CalculatorPage'));
const MyOrdersPage = loadlazy(() => import('./calculator/myorders/MyOrdersPage'));
const NewProductPage = loadlazy(() => import('./projects/myprojectsv3/NewProductPage'));

const QuestionnairePage = loadlazy(() => import('./questionnairePT/scenes/QuestionnairePage'));

const CreateProductMobile = loadlazy(() => import('./autoBuild/createProductMobile/CreateProductMobile'));
//#endregion

export class App extends React.Component {
    static ModulesLoadedCount = 1;
    constructor(props) {
        super(props);
        this.state = {
            isModulesLoaded: false
        };
    }

    componentDidMount() {
        this.moduleLoadInterval = setInterval(() => {
            if (App.ModulesLoadedCount == 1) {
                console.log('--- loaded modules');
                clearInterval(this.moduleLoadInterval);
                this.setState({
                    isModulesLoaded: true
                });
            } else {
                console.log('--- loading modules');
            }
        }, 50);

        this.unlisten = history.listen((location, action) => {
            window.scrollTo(0, 0);
            if (action == "PUSH") {
                HistoryStorage.push(location.pathname);
            }
        });
    }
    componentWillUnmount() {
        if (this.unlisten)
            this.unlisten();
    }

    render() {
        let showLiveSup =
            window.location.pathname.indexOf("/embedding/") == -1 &&
            window.location.pathname.indexOf("/company/") == -1 &&
            window.location.pathname.indexOf("/companyedit/") == -1 &&
            window.location.pathname.indexOf("/questionnaire") == -1 &&
            window.location.pathname.indexOf("/createProductMobile") == -1;
        const showCookieBanner = window.location.pathname.indexOf("/embedding/") == -1 &&
            window.location.pathname.indexOf("/createProductMobile") == -1;

        if (!this.state.isModulesLoaded) {
            return <FullPageLoaderNoText />;            
        }

        return (
            <Suspense fallback={<FullPageLoaderNoText />}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        {/*<ReduxToastr className='alert-font' timeOut={5000} />*/}
                        <Router history={history}>
                            <ErrorBoundary>
                                <GuestRoute exact layout={MainLayoutHome} path='/' component={HomePage} />
                                <GuestRoute exact layout={MainLayoutHome} path='/ru/' component={HomePage} />
                                <GuestRoute exact layout={MainLayoutHome} path='/en/' component={HomePage} />

                                <GuestRoute exact layout={MainLayoutFullWidth} hideFooter={true} path='/:lang?/home/projects' component={HomeCatalogTurnkeyPage} />
                                <GuestRoute exact layout={MainLayoutFullWidth} hideFooter={true} path='/:lang?/kovanie-izdeliya-ceni/:productType?/:productSubType?/:regionKey?/:countryCode?' component={HomeCatalogTurnkeyPage} />
                                <GuestRoute exact layout={MainLayoutFullWidth} hideFooter={true} path='/:lang?/kovanie-izdeliya-ceni-v4/:productType?/:productId?' component={HomeCatalogPageV4} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/kovanie-izdeliya-detali/:productId' component={CatalogProductDetails} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/ispolnitel-detali/:id/:productId?' component={ExecutorDetailsPage} />

                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/diy-kovanie-izdeliya-detali/:productId' component={CatalogProductPageDIY} /> {/**/}

                                <GuestRoute exact layout={MainLayout} path='/:lang?/goods' component={GoodsListPage} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/goods/details/:id' component={GoodsDetailsPage} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/elements' component={ElementsListPage} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/kovanie-elementi-kovki/:elementType' component={ElementsListPage} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/kovanie-elementi-kovki' component={ElementsListPage} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/kovanie-elementi-kovki-details/:id' component={ElementDetailsPage} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/manufacturers' component={ManufacturersPage} />

                                <GuestRoute exact layout={MainLayout} path='/:lang?/aboutProject' component={SolutionFooter} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/solution' component={MainSolutionPage} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/solution/:id' component={SolutionPage} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/privacy' component={SolutionFooter} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/termsofuse' component={SolutionFooter} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/tariffs' component={SolutionFooter} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/delivery' component={SolutionFooter} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/contacts' component={SolutionFooter} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/executor-projects' component={SolutionFooter} />

                                <GuestRoute exact layout={MainLayout} path='/:lang?/login/:p?' component={LoginPage} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/login/recoverPassword' component={RecoverPasswordPage} />

                                <GuestRoute exact layout={MainLayout} path='/:lang?/register' component={RegisterPage} />
                                <UnConfirmAccountRoute layout={MainLayout} path='/:lang?/confirmaccount' component={VerificateAccountPage} />

                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile' component={MyInfoPage} />
                                <UserRouteExecutor layout={MyProfileLayout} path='/:lang?/myprofile/specialty' component={MySpecialtyPage} />
                                {/*<UserRoutePortfolio exact layout={MyProfileLayout} path='/:lang?/myprofile/portfolio' component={ListMyPortfolioPage} />*/}
                                {/*<UserRoutePortfolio layout={MyProfileLayout} path='/:lang?/myprofile/portfolio/edit/:id' component={PortfolioItemPage} />*/}
                                {/*<UserRoutePortfolio layout={MyProfileLayout} path='/:lang?/myprofile/portfolio/add' component={PortfolioItemPage} />*/}
                                <UserRoutePortfolio layout={MyProfileLayout} path='/:lang?/myProfile/calculationfactors' component={CalculateFactorsPage} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/siteportfolio' component={EditSiteProtforlioPage} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/myproductportfolio' component={EditUserProductsPage} />
                                <UserRouteSeller exact layout={MyProfileLayout} path='/:lang?/myprofile/myelements' component={MyElementsPage} />
                                <UserRouteSeller exact layout={MyProfileLayout} path='/:lang?/myprofile/myelement/edit/:id' component={MyElementPage} />
                                <UserRouteSeller exact layout={MyProfileLayout} path='/:lang?/myprofile/myelement/add' component={MyElementPage} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/myelements/bulkuploadinfo' component={BulkUploadInfoPage} />
                                <UserRoute exact layout={MainLayoutFullWidth} path='/:lang?/myprofile/elementmatch' component={ElementMatchPage} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/feedbacks' component={MyFeedbacksPage} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/feedback/:id' component={FeedbackChatPage} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/myeditorsessions' component={MyEditorSessions} />
                                <UserRoute exact layout={MainLayout} path='/:lang?/myprofile/mygoods' component={MyGoods} />
                                <UserRoute exact layout={MainLayout} path='/:lang?/myprofile/addgoods/:id?' component={AddGoods} />
                                <UserRoute exact layout={MainLayout} path='/:lang?/myprofile/addpaints/:id?' component={AddPaints} />
                                <UserRoute exact layout={MainLayout} path='/:lang?/myprofile/addprofsheet/:id?' component={AddProfsheet} />
                                <UserRoute exact layout={MainLayout} path='/:lang?/myprofile/addmetalrolling/:id?' component={AddMetal} />
                                <UserRoute exact layout={MainLayout} path='/:lang?/myprofile/addGateAutomation/:id?' component={AddGateAutomation} />
                                <UserRoute exact layout={MyProfileLayout} path='/:lang?/myprofile/balance' component={BalancePage} />

                                {/*<UserRoute exact layout={MainLayout} path='/project/create/:id?' component={CreateProjectPage} />*/}
                                {/*<UserRoute exact layout={MainLayout} path='/project/myprojects' component={MyProjectsPage} />*/}
                                {/*<UserRoute exact layout={MainLayout} path='/project/details/:id' component={MyProjectDetailsPage} />*/}
                                {/*<GuestRoute exact layout={MainLayout} path='/project/executer/:id' component={ExecuterPage} />*/}
                                <UserRoute exact layout={MainLayoutFullWidth} path='/:lang?/projectn/myprojects/:sketchId?' component={MyProjectsPageV2} />
                                <UserRoute exact layout={MainLayoutFullWidth} path='/:lang?/myorders' component={MyOrdersPage} />
                                <UserRoute exact layout={MainLayoutFullWidth} path='/:lang?/calculator/:id?' component={CalculatorPage} />
                                <UserRoute exact layout={MainLayoutFullWidth} path='/:lang?/myprojectsv3' component={MyProjectsV3} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/myproduct/:id' component={MyProductDetails} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/product/:id/:isCustomer?&:isBuyer?&:isToBuffer?' component={MyProductDetails} />
                                <GuestRoute exact layout={MainLayoutFullWidth} path='/:lang?/product/:id/:isCustomer?&:isBuyer?' component={MyProductDetails} />
                                <UserRoute exact layout={MainLayoutFullWidth} path='/:lang?/createProduct/:sketchId?' component={NewProductPage} />

                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/myinvitations' component={ExecuterMyInvitationsPage} />*/}
                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/projectdetails/:publicationId' component={ExecuterProjectDetailsPage} />*/}
                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/search' component={ExecuterProjectFindProjectsPage} />*/}
                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/mypropositions' component={ExecuterProjectMyPropositionsPage} />*/}
                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/customerdetails/:id' component={ECustomerPage} />*/}
                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/mycontracts' component={ExecuterMyContractsPage} />*/}
                                {/*<UserRouteExecutor exact layout={MainLayout} path='/projectExecuter/contractdetails/:id' component={ExecuterContractDetailsPage} />*/}

                                {/*<UserRoute exact layout={MainLayout} path='/chat/:id?' component={ChatPage} />*/}
                                <GuestRoute exact layout={MainLayout} path='/:lang?/favorite/products' component={FavoriteProductsPage} />


                                <GuestRoute exact layout={MainLayout} path='/:lang?/instructions/:IsInstruct/' component={AllInstructionsPage} />
                                <GuestRoute layout={MainLayout} path='/:lang?/instruction/:id' component={InstructionPage} />
                                <GuestRoute exact layout={MainLayout} path='/:lang?/basket' component={BasketPage} />

                                <UserRouteExecutor exact layout={MainLayout} path='/:lang?/autoBuildEdit/sketches' component={MySketchesPage} />
                                <UserRouteExecutor exact layout={MainLayout} path='/:lang?/autoBuildEdit/sketchesWithElements' component={MySketchesElementsPage} />
                                <UserRouteExecutor exact layout={MainLayoutFullWidth} path='/:lang?/autoBuildEdit/sketch/:id?' component={EditSketchPage} />
                                <UserRouteExecutor exact layout={MainLayout} path='/:lang?/autoBuildEdit/templates' component={MyAutoBuildTemplatesPage} />
                                <UserRouteExecutor exact layout={MainLayoutFullWidth} path='/:lang?/autoBuildEdit/template/:id?' component={EditAutoBuildTemplatePage} />

                                <UserRouteExecutor exact layout={MyProfileLayout} path='/:lang?/myprofile/mycatalog' component={ExecutorSettingsPage} />
                                <UserRouteExecutor exact layout={MyProfileLayout} path='/:lang?/myprofile/mycompanysite' component={SiteCompanyServicePage} />

                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/company/:company_name' component={CompanyPage} />
                                {/*<GuestRoute exact layout={MyCompanyLayout} path='/company/:company_name/about' component={CompanyPageAbout} />*/}
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/company/:company_name/contacts' component={CompanyPageContacts} />
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/company/:company_name/portfolio' component={CompanyPagePortfolio} />
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/company/:company_name/catalog/:isProducts' component={CompanyPage3dCatalog} />
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/company/:company_name/catalog/:isProducts/details/:id' component={CompanyPageProductsDetails} />
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/companyedit/:company_name' component={CompanyPage} />
                                {/*<GuestRoute exact layout={MyCompanyLayout} path='/companyedit/:company_name/about' component={CompanyPageAbout} />*/}
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/companyedit/:company_name/contacts' component={CompanyPageContacts} />
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/companyedit/:company_name/portfolio' component={CompanyPagePortfolio} />
                                <GuestRoute exact layout={MyCompanyLayout} path='/:lang?/companyedit/:company_name/catalog/:isProducts' component={CompanyPage3dCatalog} />

                                <UserRoute exact layout={MainLayout} path='/:lang?/autoBuild/createProduct/:id?' component={AutoBuildCreateProduct} />

                                <Route path='/embedding/key=:key&lang=:lang' component={EmbedingCatalogPage} />
                                <Route path='/embedding/details/id=:id&key=:key&lang=:lang' component={EmbeddingDetailsPage} />
                                <Route exact path='/embedding/:key/:lang/:productType?' component={EmbedingCatalogPage} />
                                <Route exact path='/embedding/details/:id/:key/:lang/:productType?' component={EmbeddingDetailsPage} />
                                <Route layout={MainLayout} path='/:lang?/questionnaire/:productTypeKey?' component={QuestionnairePage} />

                                <Route path='/createProductMobile' component={CreateProductMobile} />

                                
                                {showCookieBanner &&
                                    <CookiesBanner />
                                }

                                {/*
                                {showLiveSup &&
                                    <LiveSupport />
                                }
                                {showLiveSup &&
                                    <QuestionnaireButton />
                                }
                                */}
                                <AppTitleHelper history={history} />

                                <ToastrView />
                            </ErrorBoundary>
                        </Router>
                    </ConnectedRouter>
                </Provider>
            </Suspense>
        )
    }
}

export default App;
